import { Grid, Paper, Avatar, TextField, FormControlLabel, Checkbox, Button, Snackbar } from "@mui/material";
import React, { useState, useContext } from "react";
import { AuthContext } from "../contexto/Auth";
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { api, api_host } from "../service/Api";

const Login = () => {

  const { setUser, setCnesAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [apichange, setApiChange] = useState(false);
  const [cnes, setCnes] = useState('');
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [loginValidation, setLoginValidation] = useState(false);
  const [senhaValidation, setSenhaValidation] = useState(false);
  const [open, setOpen] = useState(false);

  function Sign() {
    api_host.get('Usuario/Login?login=' + login.toLocaleLowerCase() + '&senha=' + senha.toLocaleLowerCase() + '&cnes=' + cnes)
      .then((response) => {
        const loggedUser = response.data;
        const id = response.data;

        localStorage.setItem("user", JSON.stringify(loggedUser));
        localStorage.setItem("id", id);
        localStorage.setItem("cnes", cnes);
        console.log(response.data);
        setCnesAuth(cnes);
        setUser(loggedUser);
        console.log("Auth.js:" + loggedUser);

        if (response.data[0].codUsuario != "") {
          navigate("/home");
        } else {
          setOpen(true);
        }
        console.log(api_host);
      }).catch((error) => {
        setApiChange(true);
        console.log(error);
        throw error;
      });
  }

  const pedidoGet = async () => {
    if (login === "") {
      setLoginValidation(true);
    }
    if (senha === "") {
      setSenhaValidation(true);
    }

    if (login != "" && senha != "" && cnes != "") {
      Sign();
    }
  }

  function loginval(e) {
    setLogin(e);
    setLoginValidation(false);
  }
  function senhaval(e) {
    setSenha(e);
    setSenhaValidation(false);
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const paperStyle = { padding: 20, height: '70vh', width: 280, margin: "20px auto" }
  const btnstlye = { marrgin: '8px 0' }
  const textFieldStyle = { margin: '8px 0' }

  return (
    <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity="error">Usuario/Senha invalidos, tente novamente!</Alert>
      </Snackbar>
      <Paper elevation={10} style={paperStyle}>
        <Grid align='center'>
          <Avatar src="/favicon.icon.jpeg" />
          <h2>Login</h2>
        </Grid>
        <TextField error={loginValidation} label='CNES' id={cnes !== "" ? "outlined-error" : ""} onChange={(e) => setCnes(e.target.value)} style={textFieldStyle} placeholder='Entre com seu CNES' fullWidth />
        <TextField error={loginValidation} label='Login' id={login !== "" ? "outlined-error" : ""} onChange={(e) => loginval(e.target.value)} style={textFieldStyle} placeholder='Entre com seu codigo de login' fullWidth />
        <TextField error={senhaValidation} label='Senha' id={senha !== "" ? "outlined-error" : ""} onChange={(e) => senhaval(e.target.value)} style={textFieldStyle} placeholder='Digite sua senha' type='password' fullWidth />
        <FormControlLabel
          control={
            <Checkbox
              name="checkeB"
              color="primary"
            />
          }
          label="Lembrar senha"

        />
        <Button type='submit' color='primary' variant="contained" style={btnstlye} fullWidth onClick={() => pedidoGet()} >Entrar</Button>

      </Paper>
    </Grid>
  );
}


export default Login;
