import axios from "axios";

export const api_host = axios.create({
  baseURL: "http://192.168.1.110:3000/api/",
});
export const api = axios.create({
  baseURL: "http://189.112.126.231:1060/api/",
});

export const create = async (login, senha) => {
  api.get('Usuario/Login?login=' + login + '&senha=' + senha)
    .then((response) => {
      return response.data;
    });
};


