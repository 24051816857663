import React from 'react';
import Bar from '../componentes/Bar';
import { Box, CardContent, Grid, Paper } from '@mui/material';
import TabelaExamesPorPacientes from '../componentes/TabelaExamesPorPaciente';


const Exames = () => {

  const paperStyle = { padding: 30, height: 'auto', width: 1243, margin: "10px auto" }

  return (
    <>
      <Bar />
      <Box height={'auto'} />
      <Box sx={{ display: 'flex' }}>
        {/* <Dashboard /> */}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Grid>
            <Paper elevation={10} style={paperStyle}>
              <CardContent>
                <TabelaExamesPorPacientes />
              </CardContent>
            </Paper>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Exames;

