import { AppBar, Box, IconButton, TableCell, Toolbar, Typography } from "@mui/material";
import { AuthContext } from '../contexto/Auth';
import React, { useContext, useEffect, useState} from 'react';
import { ExitToApp } from "@mui/icons-material";



  export default function Bar  ()  {
  const { authenticated, logout } = useContext(AuthContext);

  const [userName, setUserName] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserName(user[0].nome);
    }
  }, []);
 


  

  const handleLogout = () => {
    logout();
  };
  return (
    <Box sx={{ flexGrow: 1 , marginBottom: 5}} >
      <AppBar position="relative" elevation={0} style={{background:"#a0ddf9", color:"#f8feed"}}>
        <Toolbar style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{display: "flex"}} >
            <form className="form">
              <div className="field" >
                <label type="nome">{`Dr. ${userName}`}</label>
             
              </div>
            </form>
          </Typography>  
           <IconButton
           size="large"
           edge="end"
           color="inherit"
           aria-label="open drawer"
           sx={{ mr: 2 }} onClick={handleLogout}
           >
              <ExitToApp/>
             </IconButton> 
        </Toolbar>
      </AppBar>
    </Box>
  );
}

