import React, { useState, useEffect, Component, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, Divider, Snackbar, TableHead, TableRow, TableFooter, Button, Typography, TextField, CardContent, Card, Select, InputLabel, FormControl, MenuItem, makeStyles, IconButton, Popover, Box } from '@mui/material';
import { AuthContext } from "../contexto/Auth";
import { Alert } from '@mui/material';
import { api, api_host, create } from "../service/Api";
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import axios from 'axios';
import { ArrowBack } from '@mui/icons-material';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style/Exames.css";
import { convertToHTML } from 'draft-convert';
import HtmlToRtfBrowser from 'html-to-rtf-browser';


function TabelaExamesPorPaciente({ paciente }) {
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(ContentState.createFromText('')),
  );
  const [apichange, setApiChange] = useState(false);
  const { CnesAuth } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState([])
  const [pacientes, setPacientes] = useState([]);
  const [exames, setExames] = useState([]);
  const [nome, setNome] = useState("");
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [registroPaciente, setRegistroPaciente] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [codigoLaudo, setCodigoLaudo] = useState(null);
  const [nomeLaudosPadrao, setNomeLaudosPadrao] = useState([]);
  const [codigoExameSelecionado, setCodigoExameSelecionado] = useState(null);
  const [codigoConta, setCodigoConta] = useState(null);
  const [codTipoConta, setCodTipoConta] = useState(null);
  const [nLaudo, setNLaudo] = useState(null);
  const [conteudoLaudo, setConteudoLaudo] = useState('');
  const [opcoesLaudosPadrao, setOpcoesLaudosPadrao] = useState([]);
  const [nomeLaudoSelecionado, setNomeLaudoSelecionado] = useState("");
  const [codigoLaudoSelecionado, setCodigoLaudoSelecionado] = useState("");
  const [conteudoLaudoCompleto, setConteudoLaudoCompleto] = useState("");
  const [resultadoLaudo, setResultadoLaudo] = useState('');
  const [exibirTextField, setExibirTextField] = useState(false);
  const [exibirCampoLaudo, setExibirCampoLaudo] = useState(false);
  const [valorCampoLaudo, setValorCampoLaudo] = useState(nomeLaudoSelecionado);
  const [laudosLaudados, setLaudosLaudados] = useState([]);
  const [botoesLaudar, setBotoesLaudar] = useState('');
  const [examesLaudados, setExamesLaudados] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [convertedContent, setConvertedContent] = useState(null);

  const [fonte, setFonte] = useState(() => {
    const fonteSalva = JSON.parse(localStorage.getItem("fonte"));
    return fonteSalva ?? {
      tamanho: "normal",
      cor: "preto",
      estilo: "normal"
    };
  });
  const navigate = useNavigate();

  const handleResultadoLaudoChange = (event) => {
    setResultadoLaudo(nomeLaudoSelecionado + ' - ' + event.target.value);
  };

  const handleNomeLaudoPadraoChange = (event) => {
    setNomeLaudoSelecionado(event.target.value);
    // const codigoLaudoSelecionado = opcoesLaudosPadrao.find((opcao) => opcao.nomeLaudo === event.target.value)?.codigoLaudoPadrao;
    // setCodigoLaudoSelecionado(codigoLaudoSelecionado);
    console.log(editorState);
    setEditorState(() => EditorState.createWithContent(ContentState.createFromText(event.target.value)));
    setConteudoLaudo(event.target.value);
  };

  const handleConteudoLaudoChange = (event) => {
    setConteudoLaudo(event.target.value);
  };

  const handleConteudoLaudoCompletoChange = (event) => {
    setConteudoLaudoCompleto(`${nomeLaudoSelecionado} ${event.target.value}`);
  };


  const handleConfirmarClick = () => {
    console.log("Clicou no botão Confirmar. Nome do Laudo Padrao Selecionado:", nomeLaudoSelecionado);
    setNomeLaudoSelecionado(nomeLaudoSelecionado);
    setIsOpen(true);
    setConteudoLaudo((prevConteudoLaudo) => prevConteudoLaudo + nomeLaudoSelecionado);
  };

  function handleClose() {
    setIsOpen(false);
    setConteudoLaudo("");
    setCodigoExameSelecionado(null);
    setNLaudo(null);
    setExibirTextField(false);
  }

  const handleClick = (codigoExame, nLaudo) => {
    console.log("Clicou no botão Laudar. Código do exame selecionado:", codigoExame);
    setCodigoExameSelecionado(codigoExame);
    console.log("Código do exame selecionado atualizado:", codigoExameSelecionado);
    setExibirTextField(true);
    setIsOpen(true);
    console.log("isOpen atualizado:", isOpen);

    setBotoesLaudar((prev) => ({
      ...prev,
      [nLaudo]: { estado: "Laudando", desabilitado: true },
    }));
  };

  function pegarParametros() {
    var query = window.location.search.slice(1);
    var partes = query.split('&');
    var data = {};
    partes.forEach(function (parte) {
      var chaveValor = parte.split('=');
      var chave = chaveValor[0];
      var valor = chaveValor[1];
      data[chave] = valor;
    });

    setRegistroPaciente(partes[0]);
    console.log(partes[0]);
    loadPacientes(partes[0]);
  }

  function convertHtmlToRtfEnvia(html) {
    var htmlToRtf = new HtmlToRtfBrowser();
    const rtf = htmlToRtf.convertHtmlToRtf(html)
    return rtf;
  };


  async function loadPacientes(regPac) {
    try {
      if (regPac != 0) {
        await (apichange === false ? api : api_host).get(`/Usuario/PacienteInterno?prNomePaciente=A&DtInit=0000-00-00&DtFim=0000-00-00&RegPac=${regPac}&cnes=${CnesAuth ? CnesAuth : localStorage.getItem("cnes")}`)
          .then((response) => {
            console.log(response.data);
            setExames(response.data);
            // Definindo os estados com as informações correspondentes
            setCodigoConta(response.data[0].codigoConta);
            setCodTipoConta(response.data[0].codigoTipoConta);
            setNLaudo(response.data[0].nLaudo);
          }).catch((error) => {
            console.log(error);
            setApiChange(true);
            loadPacientes();
            throw error;
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchData() {
    try {
      await (apichange === false ? api : api_host).get(`Usuario/LaudoPadrao?cnes=${CnesAuth ? CnesAuth : localStorage.getItem("cnes")}`).then((response) => {
        console.log(response.data); // Adicionando o console.log para exibir a resposta
        setOpcoesLaudosPadrao(response.data);
      }).catch((error) => {
        console.log(error);
        throw error;
      });
    } catch (error) {
      console.error(error);
      setApiChange(true);
      fetchData();
    }
  }

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
    setConteudoLaudo(convertedContent);

  }, [editorState]);

  useEffect(() => {

    fetchData();
    console.log(editorState);
  }, []);

  useEffect(() => {
    if (window.document.getElementsByClassName('rdw-block-wrapper').length != 0) {
      window.document.getElementsByClassName('rdw-block-wrapper')[0].style.display = 'none'
    }
  });


  const enviarLaudo = async () => {
    try {
      if (!codigoExameSelecionado) {
        alert("Não foi selecionado nenhum exame.");
        return;
      }
      console.log(`Enviando laudo para o exame ${codigoExameSelecionado}.`);

      let laudoConteudo = convertHtmlToRtfEnvia(conteudoLaudo);

      if (laudoConteudo) {
        const data = {
          cnes: CnesAuth ? CnesAuth : localStorage.getItem("cnes"),
          codigoExame: codigoExameSelecionado,
          resultado: laudoConteudo,
          codigoConta: codigoConta,
          codTipoConta: codTipoConta,
          codUsuario: codUsuario,
          nLaudo: nLaudo,
        };
        console.log(data);
        console.log("codTipoConta:", codTipoConta);
        console.log("codigoConta:", codigoConta);
        await api.post(`Usuario/Raires?`, data).then((response) => {
          setOpen(true);
        }).catch((error) => {
          console.log(error);
          setApiChange(true);
          enviarLaudo();
          throw error;
        });
      }

      // Atualiza o estado do botão "Laudar" correspondente para indicar que o exame foi laudado
      setBotoesLaudar((prev) => ({
        ...prev,
        [nLaudo]: { estado: "Laudado", desabilitado: true },
      }));

      setExamesLaudados((prev) => ({
        ...prev,
        [nLaudo]: true,
      }));

      setCodUsuario(codUsuario);
      handleClose();
    } catch (error) {
      console.error(error);
    }

  };


  const [codUsuario, setCodUsuario] = useState(() => {
    const storedState = JSON.parse(localStorage.getItem("user")) || {};
    console.log("storedState:", storedState); // Adicione um log para verificar o valor de storedState
    const storedCodUsuario = storedState[0]?.codUsuario;
    console.log("storedCodUsuario:", storedCodUsuario); // Adicione um log para verificar o valor de storedCodUsuario
    return storedCodUsuario || null;
  });
  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify([{ codUsuario: codUsuario }]));
  }, [codUsuario]);

  useEffect(() => {
    pegarParametros();
  }, [reload]);

  const handleFonteChange = (event) => {
    const { name, value } = event.target;
    setFonte((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const [estiloFonte, setEstiloFonte] = useState({
    fontSize: "18px",
    color: "#000",
    fontWeight: "normal",
    fontStyle: "normal",
  });

  useEffect(() => {
    const estiloFonte = {
      fontSize: fonte.tamanho === "pequeno" ? "12px" : fonte.tamanho === "normal" ? "16px" : "20px",
      color: fonte.cor === "preto" ? "#000" : fonte.cor === "vermelho" ? "#f00" : fonte.cor === "azul" ? "#00f" : "#000",
      fontWeight: fonte.estilo === "bold" ? "bold" : "normal",
      fontStyle: fonte.estilo === "italic" ? "italic" : "normal"
    };
    setEstiloFonte(estiloFonte);

    localStorage.setItem("fonte", JSON.stringify(fonte));
  }, [fonte]);

  useEffect(() => {
    const fonteSalva = JSON.parse(localStorage.getItem("fonte"));
    if (fonteSalva) {
      setFonte(fonteSalva);
    }
  }, []);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const btnstlye = { marrgin: '2px 0' }

  const handleCloseMsg = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function handleBack() {
    navigate(`/Home`);
  }

  return (
    <TableContainer>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={3000} onClose={handleCloseMsg}>
        <Alert severity="success">Salvo com Sucesso!</Alert>
      </Snackbar>
      <div style={{ position: "relative" }}>
        <IconButton size={'small'} onClick={handleBack}>
          <ArrowBack />
        </IconButton>
      </div>
      <Table>
        <TableBody>
          {exames.reduce((pacientes, exame) => {
            const index = pacientes.findIndex((paciente) => paciente.nomePaciente === exame.nomePaciente && paciente.idadePaciente === exame.idadePaciente);
            if (index === -1) {
              pacientes.push({ nomePaciente: exame.nomePaciente, idadePaciente: exame.idadePaciente, exames: [exame] });
            } else {
              pacientes[index].exames.push(exame);
            }
            return pacientes;
          }, []).map((paciente, index) => (
            <React.Fragment key={index}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <p style={{ fontSize: '18px', fontFamily: 'Arial' }} >{paciente.nomePaciente}</p>
                <p style={{ fontSize: '18px', marginLeft: 15, fontFamily: 'Arial' }}>{paciente.idadePaciente}</p>
              </div>
              <Divider />
              <TableRow>
                <TableCell colSpan={3}>
                  <Table>
                    <TableBody>
                      {paciente.exames.map((exame, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell style={estiloFonte} align="left">{exame.nomeExame}</TableCell>
                            <TableCell align="right">
                              <Button
                                className="my-button"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  console.log('Botão Laudar clicado!');
                                  handleClick(exame.codigoExame);
                                }}
                              >
                                {examesLaudados[exame.nLaudo] ? 'Laudado' : 'Laudar'}
                              </Button>

                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow></TableRow>
        </TableFooter>
      </Table>
      {isOpen && (
        <Card>
          <CardContent>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
              <Typography variant="h5" component="h2" >
                Laudar
              </Typography>
              <div >
                <FormControl variant="standard" size="small">
                  <InputLabel id="demo-simple-select-label">Opções de Laudo Padrão</InputLabel>
                  <Select
                    value={nomeLaudoSelecionado}
                    onChange={handleNomeLaudoPadraoChange}
                    style={{ width: '210px' }}
                    MenuProps={{ sx: { maxHeight: 300 } }}
                  >
                    {opcoesLaudosPadrao.map((opcao) => (
                      <MenuItem style={{ width: '350px' }} key={opcao.codigoLaudoPadrao} value={opcao.nomeLaudo}>
                        {opcao.nomeLaudo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <Button className="my-button" variant="contained" size="medium" onClick={()=>fetchData()}>Confirmar</Button> */}
              </div>
            </div>
            {exibirTextField && (
              <div>
                <div style={{ marginBottom: '16px' }}>
                  {/* <TextField
                    id="conteudo-laudo"
                    label="Digite o Laudo"
                    value={conteudoLaudo}
                    onChange={(event) => setConteudoLaudo(event.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                    margin="normal"
                    size={50}
                    style={{ width: '40%' }}
                  /> */}
                  <div style={{ borderColor: '#fff', borderWidth: 1 }}>
                    <Editor
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      toolbar={{
                        options: ['inline', 'blockType']
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Button className="my-button" variant="contained" size="small" style={{ marginRight: '8px' }} onClick={enviarLaudo} >Salvar Laudo</Button>
                  <Button className="my-button" variant="contained" size="small" style={{ marginLeft: '8px' }} onClick={handleClose}>Cancelar</Button>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      )}

    </TableContainer>
  );
};

export default TabelaExamesPorPaciente;
