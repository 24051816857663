import React, { useContext } from 'react';
import './App.css';
import {Routes, Route, BrowserRouter, Navigate} from "react-router-dom";
import { AuthProvider, AuthContext } from './contexto/Auth';
import Login from './pages/Login';
import Home from './pages/Home';
import Exames from './pages/Exames';

const App = () => {

  const Private =({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <div className="loading">Carregando...</div>;
    }

    if(!authenticated){
      return <Navigate to="/"/>;

    }
      
    return children;
  };

  return (
    <BrowserRouter>
     <AuthProvider>
      <Routes>
       <Route exat path="/" element=
       {<Login/>}/>
       <Route exat path="/home" element={
        <Private>
          <Home/> 
       </Private>  
    } 
    />
       <Route exat path="/exames" element=
       {<Exames/>}/>
    </Routes>
    </AuthProvider>
    </BrowserRouter>
  );

 };

 export default App;