import React, { useState, useEffect, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TablePagination, Box, Button, InputLabel, FormControl, Input } from '@mui/material';
import { api, api_host, create } from "../service/Api";
import { format, subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Exames from '../pages/Exames';
import { isValid } from "date-fns";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from "../contexto/Auth";


function TabelaPacientes({ onSearch }) {
  const { CnesAuth, setfilter, filter } = useContext(AuthContext);
  const [apichange, setApiChange] = useState(false);
  const [pacienteInterno, setPacienteInterno] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pacientes, setPacientes] = useState([]);
  const [exames, setExames] = useState([]);
  const navigate = useNavigate();
  const [nome, setNome] = useState("");
  const [dataFinal, setDataFinal] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [dataInicial, setDataInicial] = useState(format(subDays(new Date(), 15), 'yyyy-MM-dd'));
  const [dataFinalParam, setDataFinalParam] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  function goToExamesPage(registroPaciente) {
    navigate(`/Exames?${registroPaciente}`);
  };

  const handleChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleSubmit = (event) => {
    event.preventDefault(); // previne o comportamento padrão do formulário que é recarregar a página
    onSearch(nome, dataInicial, dataFinalParam); // chama a função onSearch passando os valores dos campos de pesquisa
  };

  async function loadPacientes(dataInicial, dataFinalParam, nome) {
    try {
      if (isValid(new Date(dataInicial)) && isValid(new Date(dataFinal))) {
        handleOpen();
        dataFinalParam = format(new Date(dataFinal), "yyyy-MM-dd");
        dataInicial = format(new Date(dataInicial), "yyyy-MM-dd");
        const response = await (apichange === false ? api : api_host).get(
          `/Usuario/PacienteInterno?prNomePaciente=${nome !== "" ? nome.toUpperCase() : "A"
          }&DtInit=${filter?.dataInicial != dataInicial ? dataInicial : filter?.dataInicial }&DtFim=${dataFinalParam}&RegPac=0&cnes=${CnesAuth ? CnesAuth : localStorage.getItem("cnes")}`
        ).then((response)=>{
          console.log(`/Usuario/PacienteInterno?prNomePaciente=${nome !== "" ? nome.toUpperCase() : "A"
        }&DtInit=${filter.dataInicial!=dataInicial ? filter?.dataInicial : dataInicial}&DtFim=${dataFinalParam}&RegPac=0&cnes=${CnesAuth ? CnesAuth : localStorage.getItem("cnes")}`);
          const dataFormatada = response.data.map((item) => {
            const novaData = format(new Date(item.dataSolicitacao), "dd/MM/yyyy");
            return { ...item, dataSolicitacao: novaData };
          });
          handleClose();
          setPacienteInterno(dataFormatada);
        }).catch((error) => {
          console.log(error);
          setApiChange(true);
          loadPacientes();
          throw error;
        });
      } else {
        handleClose();
        console.log("Verificação de datas");
      }
    } catch (error) {
      console.log(error);
    }
  }


  function handlePesquisa(DataInicial, DataFinal, Nome) {
    setfilter({
      dataInicial: DataInicial,
      dataFinal: DataFinal,
      nome: Nome
    });
    loadPacientes(DataInicial, DataFinal, Nome);
  }

  useEffect(() => {
    handlePesquisa(dataInicial, dataFinal, nome);

    console.log(CnesAuth ? CnesAuth : localStorage.getItem("cnes"));
  }, []);

  const btnstlye = { margin: '2px 0' }

  return (
    <TableContainer>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Table>
        <div>
          <TableRow>
            <TableCell>
              <FormControl>
                <Input
                  id="nome"
                  value={nome}
                  onChange={(event) => setNome(event.target.value)}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl>
                <Input
                  id="dataInicial"
                  type="date"
                  value={dataInicial}
                  max={format(new Date(), 'yyyy-MM-dd')}
                  onChange={(event) => setDataInicial(event.target.value)}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl>
                <Input
                  id="dataFinal"
                  type="date"
                  value={dataFinal}
                  max={format(new Date(), 'yyyy-MM-dd')}
                  onChange={(event) => setDataFinal(event.target.value)}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <Button type="button" color='primary' variant="contained" onClick={() => handlePesquisa(dataInicial, dataFinal, nome)}>Pesquisar</Button>
            </TableCell>
          </TableRow>
        </div>
        <TableHead>
          <TableRow>
            <TableCell>Nome Paciente</TableCell>
            <TableCell align="right">Registro Paciente </TableCell>
            <TableCell align="left">Data Solicitação</TableCell>
            <TableCell align="left">Exames</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pacienteInterno.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                {item.nomePaciente}
              </TableCell>
              <TableCell align="right">{item.registroPaciente}</TableCell>
              <TableCell align="left">{item.dataSolicitacao}</TableCell>
              <Box sx={{ '& button': { m: 1 } }}>
                <div>
                  <Button type='submit' variant="contained" size="small" onClick={() => goToExamesPage(item.registroPaciente)}>
                    Exames
                  </Button>
                </div>
              </Box>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
          </TableRow>
        </TableFooter>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={pacienteInterno.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChange}
        onRowsPerPageChange={handleChangeRowsPerPage}>
      </TablePagination>
    </TableContainer>
  );
};

export default TabelaPacientes;




