import { useState, useEffect, createContext } from "react";
import { useNavigate } from 'react-router-dom';
import { api, create } from "../service/Api";


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [filter, setfilter] = useState([]);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [CnesAuth, setCnesAuth] = useState();


    useEffect(() => {
        const recoveredUser = localStorage.getItem("user");

        if (recoveredUser) {
            setUser(JSON.parse(recoveredUser));
        }

        setLoading(false);
    }, []);

    const verificarLogin = async (login, senha) => {
        await create(login, senha).then((response) => {
            console.log(response);
        // verificar definição de user e id
            const loggedUser = response;
            const id = response;
    
    
            localStorage.setItem("user", JSON.stringify(loggedUser));
            localStorage.setItem("id", id);
    
            api.defaults.headers.Authorization = `Bearer ${id}`;
    
            setUser(loggedUser);
            console.log("Auth.js:" + loggedUser);
    
            if (response != "") {
                navigate("/home");
            } else {
                return "INVALIDO";
            }
        }).catch((error) => {
            console.log(error);
            throw error;
          });

    };

    const logout = () => {
        console.log("logout");
        localStorage.removeItem("cnes");
        localStorage.removeItem("user");
        localStorage.removeItem("id");
        api.defaults.headers.Authorization = null;
        setUser(null);
        navigate("/");
    };

    return (
        <AuthContext.Provider
            value={{ authenticated: !!user, user,filter, setfilter, CnesAuth,loading, setCnesAuth, verificarLogin, logout, setUser }}
        >
            {children}
        </AuthContext.Provider>
    );
};