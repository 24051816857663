import React from 'react';
import Bar from '../componentes/Bar';
import TabelaPacientes from '../componentes/TabelaPacientes';
import { Box, CardContent, Grid, Paper } from '@mui/material';
import Img from '../assets/LogoSistema3.png';

const Home = () => {

  const paperStyle = { padding: 30, height: 'auto', width: 1243, margin: "10px auto" }

  return (
    <>
      <Bar />
      <Box height={30} />
      <Box sx={{ display: 'flex' }}>
        {/* <Dashboard /> */}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Grid>
            <Paper elevation={10} style={paperStyle}>
              <CardContent>
                <TabelaPacientes />
              </CardContent>
            </Paper>
          </Grid>
          <div style={{display:'flex',justifyContent:'flex-end'}}>
            <img src={Img} style={{ width: 300, height: 'auto', marginTop: 50 }}></img>
          </div>
        </Box>
      </Box>
    </>
  );

}

export default Home;

